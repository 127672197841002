import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import LoadingPage from "./LoadingPage";
import { Box, Typography, TextField, IconButton, AppBar, Toolbar, Modal, Button } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ClearIcon from '@mui/icons-material/Clear';
import MessageBubble from "../components/MessageBubble";

const ChatPage = ({ chats, updateMessages }) => {
    const navigate = useNavigate();
    const { chatId } = useParams();
    const [newMessage, setNewMessage] = useState("");
    const [newMessageBase, setNewMessageBase] = useState(null);
    const [reviewInfo, setReviewInfo] = useState(null);
    const [loadedAllMessages, setLoadedAllMessages] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const scrollRef = useRef(null);

    function getMyChat() {
        return chats.find(x => x._id === chatId);
    }

    function onAnswerSuggestionClick(messageId, suggestion) {
        setNewMessage(suggestion.content);
        setNewMessageBase(suggestion._id);
        setReviewInfo({
            messageId: messageId,
            model: suggestion.model
        });
    }

    useEffect(() => {
        const fetchChatDetails = async () => {
            try {
                const response = await axios.get(`/chat/getById/${chatId}`);
                updateMessages(response.data.messages);
                setLoadedAllMessages(true);
            } catch (error) {
                console.error("Error fetching chat details:", error);
            }
        };

        fetchChatDetails();
    }, [chatId]);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [getMyChat().messages.length, loadedAllMessages]);

    const sendMessage = async () => {
        if (newMessage !== "") {
            const msg = newMessage;
            const msgBase = newMessageBase;
            setNewMessage("");
            setNewMessageBase(null);
            try {
                await axios.post(`/chat/sendAssistantMessage/${chatId}`, {
                    content: msg,
                    messageSuggestionId: msgBase
                });

            } catch (error) {
                setNewMessage(msg);
                setNewMessageBase(msgBase);
                console.error("Error sending message:", error);
            }
        }
    };

    const handleThumbsUp = async () => {
        if (reviewInfo) {
            try {
                await axios.post(`/chat/rateMessageSuggestion/${newMessageBase}`, {
                    ...reviewInfo,
                    rating: true,
                });
                setReviewInfo(null);
            } catch (error) {
                console.error("Error submitting thumbs up review:", error);
            }
        }
    };

    const handleThumbsDown = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleSubmitReview = async () => {
        if (reviewInfo) {
            try {
                await axios.post(`/chat/rateMessageSuggestion/${newMessageBase}`, {
                    ...reviewInfo,
                    rating: false
                });
                setReviewInfo(null);
                setModalOpen(false);
            } catch (error) {
                console.error("Error submitting review:", error);
            }
        }
    };

    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton color="inherit" onClick={() => navigate("/chat")}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginLeft: "10px" }}>
                        {getMyChat().name}
                    </Typography>
                </Toolbar>
            </AppBar>

            {loadedAllMessages ?
                <Box flex={1} overflow="auto" p={2} pb={19} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    position: 'relative'
                }}>
                    {getMyChat().messages.map((message) => (
                        <MessageBubble key={message._id} message={message} handleSuggestedAnswerClick={onAnswerSuggestionClick} pendingDerivation={newMessageBase}/>
                    ))}
                    <div ref={scrollRef} />
                </Box>
                :
                <LoadingPage />
            }

            <Box sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                p: 2,
                bgcolor: 'background.paper',
                borderTop: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                gap: 1,
                alignItems: 'center',
            }}>
                {reviewInfo && (
                    <Box sx={{
                        position: 'absolute',
                        top: '-60px',
                        left: '16px',
                        display: 'flex',
                        gap: '12px',
                        zIndex: 10,
                    }}>
                        <IconButton sx={{
                            backgroundColor: 'white',
                            boxShadow: 3,
                            '&:hover': { backgroundColor: 'white' },
                            borderRadius: '50%'
                        }} color="success" onClick={handleThumbsUp}>
                            <ThumbUpIcon />
                        </IconButton>
                        <IconButton sx={{
                            backgroundColor: 'white',
                            boxShadow: 3,
                            '&:hover': { backgroundColor: 'white' },
                            borderRadius: '50%'
                        }} color="error" onClick={handleThumbsDown}>
                            <ThumbDownIcon />
                        </IconButton>
                        <IconButton sx={{
                            backgroundColor: 'white',
                            boxShadow: 3,
                            '&:hover': { backgroundColor: 'white' },
                            borderRadius: '50%'
                        }} color="gray" onClick={()=>{
                            setReviewInfo(null);
                            setNewMessageBase(null);
                            setNewMessage("");
                        }}>
                            <ClearIcon />
                        </IconButton>
                    </Box>
                )}
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Type a message"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    multiline
                    maxRows={4}
                />
                <IconButton color="primary" onClick={sendMessage}>
                    <SendIcon />
                </IconButton>
            </Box>

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="review-modal-title"
                aria-describedby="review-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    width: '80%',
                    maxWidth: 400,
                }}>
                    <Typography id="review-modal-title" variant="h6" component="h2">
                        Provide Feedback
                    </Typography>
                    <TextField
                        id="review-modal-description"
                        fullWidth
                        multiline
                        rows={4}
                        value={reviewInfo?.review || ""}
                        onChange={(e) => setReviewInfo({
                            ...reviewInfo,
                            review: e.target.value
                        })}
                        placeholder="Enter your feedback here"
                        sx={{ mt: 2, mb: 2 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSubmitReview}
                    >
                        Submit
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default ChatPage;